/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-require-imports */
import React from 'react';
import {ProductList} from '../ProductList/ProductList';
import {IGallerySantaProps, IPropsInjectedByViewerScript} from '../../../types/galleryTypes';
import s from './GalleryApp.scss';
import classNames from 'classnames';

import {Omit} from '@wix/native-components-infra/dist/es/src/types/types';
import autobind from 'auto-bind-es5';
import {Announcer} from '@wix/wixstores-client-core/dist/es/src/a11y/announcer';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../galleryGlobalStrategy';
import {inlineStyleFix} from '../../../styles/inlineStyle';
import 'wicg-inert';
import {SideFilters} from '../../../common/components/SideFilters/SideFilters';
import {Sort} from '../../../common/components/Sort/Sort';
import {MobileFiltersAndSort} from '../../../common/components/MobileFiltersAndSort/MobileFiltersAndSort';
import {
  announceIfFilterResultChanged,
  hasRelevantFilters,
  shouldRenderSortInLayout,
  shouldShowGalleryAppInLayout,
} from './appUtils';

export enum DataHook {
  Root = 'gallery-app-root',
  Content = 'gallery-app-content',
  Container = 'gallery-app-container',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  SideFilters = 'gallery-aside-filters',
  MobileContainer = 'mobile-container',
}

export type IGalleryAppProps = Omit<IPropsInjectedByViewerScript & IGallerySantaProps, IGalleryGlobalProps['globals']> &
  IGalleryGlobalProps &
  IProvidedTranslationProps;

class GalleryAppComp extends React.Component<IGalleryAppProps> {
  private a11yAnnouncer: Announcer;

  constructor(props) {
    super(props);
    this.state = {
      isSSR: props.isSSR,
    };
    autobind(this);
  }

  public componentDidMount() {
    this.scrollToProductIfReturnedFromProductPage();
    this.a11yAnnouncer = new Announcer('gallery-announcer');
    this.props.host.registerToComponentDidLayout(this.reportAppLoaded);
  }

  public componentDidUpdate(prevProps: IGalleryGlobalProps) {
    announceIfFilterResultChanged(prevProps, this.props, () =>
      this.a11yAnnouncer.announce(
        this.props.t('announceFiltersUpdate', {
          numberOfFoundProducts: this.props.globals.products && this.props.globals.products.length,
        })
      )
    );
  }

  public componentWillUnmount() {
    this.a11yAnnouncer.cleanup();
  }

  private scrollToProductIfReturnedFromProductPage() {
    const {
      globals: {
        scrollToProduct,
        clearScrollToProduct,
        experiments: {shouldScrollToProductPositionWhenReturningFromProductPageEnabled},
      },
    } = this.props;

    if (shouldScrollToProductPositionWhenReturningFromProductPageEnabled && scrollToProduct) {
      clearScrollToProduct();
      const productItem = document.querySelector(`[data-hook="${DataHook.Root}"] [data-slug="${scrollToProduct}"]`);
      if (productItem) {
        history.replaceState({scrollY: 0}, '');
        setTimeout(() => productItem.scrollIntoView({block: 'center'}), 0);
      }
    }
  }

  private reportAppLoaded() {
    if (this.props.globals.isInteractive && typeof this.props.onAppLoaded === 'function') {
      this.props.onAppLoaded();
    }
  }

  public render() {
    const {hasSelectedFilters, styles, stylesParams, filterModels} = this.props.globals;
    const isFullWidth = styles.get(stylesParams.full_width);
    const responsive = styles.get(stylesParams.responsive);
    const {shouldShowMobile, shouldShowSort} = this.props.globals;
    const {isCategoryVisible} = this.props.globals;
    const isDesktop = !shouldShowMobile;

    if (!shouldShowGalleryAppInLayout(this.props.isLoaded, this.props.globals)) {
      return null;
    }

    const shouldRenderSideFilters = isDesktop && hasRelevantFilters(filterModels) && isCategoryVisible;
    const shouldRenderDesktopSort = isDesktop && shouldRenderSortInLayout(this.props.globals) && isCategoryVisible;
    const shouldRenderMobileFiltersAndSort = shouldShowMobile && isCategoryVisible;

    const classnames = {
      app: classNames({
        [s.galleryApp]: true,
        deviceMobile: shouldShowMobile,
        notCssPerBreakpoint: !this.props.host.usesCssPerBreakpoint,
      }),
      content: classNames({
        [s.content]: true,
        [s.responsiveWidth]: true,
        [s.contentResponsive]: responsive,
        [s.fullWidth]: isFullWidth,
      }),
      container: classNames(s.container, {
        [s.galleryContentWidth]: isDesktop && shouldRenderSideFilters,
      }),
      sideFiltersContainer: classNames({
        [s.filtersContainerWidth]: isDesktop && shouldRenderSideFilters,
        [s.sideFiltersWithSort]: isDesktop && shouldShowSort,
      }),
    };

    return (
      <>
        <style dangerouslySetInnerHTML={{__html: inlineStyleFix}} />
        <div data-hook={DataHook.Root} data-is-responsive={responsive} className={classnames.app}>
          <div data-hook={DataHook.Content} className={classnames.content}>
            {shouldRenderSideFilters && (
              <div className={classnames.sideFiltersContainer}>
                <SideFilters {...this.props.globals} />
              </div>
            )}
            <div data-hook={DataHook.Container} className={classnames.container}>
              {shouldRenderDesktopSort && <Sort />}
              {shouldRenderMobileFiltersAndSort && <MobileFiltersAndSort {...this.props.globals} />}
              {<ProductList hasFilters={hasSelectedFilters} />}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export const GalleryApp = withGlobals(withTranslations()(GalleryAppComp));
